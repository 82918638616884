import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";

function Header({ handleLogout }) {
  return (
    <Navbar
      expand="md"
      className="bg-dark shadow"
      data-bs-theme="dark"
      style={{ minHeight: "60px" }}
    >
      <div className="container-fluid">
        <Navbar.Brand
          as={Link}
          to="/bridal-contracts"
          className="text-white border-end pe-4"
        >
          Altınpark Wedding
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="nav-right" />
        <Navbar.Collapse className="text-center h-100" id="nav-right">
          <Nav className="me-auto">
            <ul className="navbar-nav mb-2 mb-lg-0">
              <li className="nav-item">
                <Nav.Link
                  as={Link}
                  to="/bridal-contracts"
                  className="nav-link text-white fs-6 fw-medium"
                >
                  Gelinlik
                </Nav.Link>
              </li>
              <li className="nav-item">
                <Nav.Link
                  as={Link}
                  to="/lite-bridal-contracts"
                  className="nav-link text-white fs-6 fw-medium"
                >
                  Üçlü Gelinlik
                </Nav.Link>
              </li>

              {process.env.FOTO_ALBUM_DURUM === "1" && (
                <li className="nav-item">

                  <Nav.Link
                    as={Link}
                    to="/photo-contracts"
                    className="nav-link text-white fs-6 fw-medium"
                  >
                    Foto Albüm
                  </Nav.Link>
                </li>

              )}

              {process.env.DUGUN_DURUM === "1" && (
                <li className="nav-item">
                  <Nav.Link
                    as={Link}
                    to="/wedding-contracts"

                    className="nav-link text-white fs-6 fw-medium"
                  >
                    Düğün
                  </Nav.Link>
                </li>
              )}


              <li className="nav-item">
                <Nav.Link
                  as={Link}
                  to="/calendar"
                  className="nav-link text-white fs-6 fw-medium"
                >
                  Takvim
                </Nav.Link>
              </li>

              <li className="nav-item">
                <Nav.Link
                  as={Link}
                  to="/extras"
                  className="nav-link text-white fs-6 fw-medium"
                >
                  Ekstralar
                </Nav.Link>


              </li>
              
              <li className="nav-item">
                <Nav.Link
                  as={Link}
                  to="/dayreport"
                  className="nav-link text-white fs-6 fw-medium"
                >
                  Gün Sonu Rapor
                </Nav.Link>


              </li>

            </ul>
          </Nav>
          <Nav className="ms-auto">
            <ul className="navbar-nav mb-2 mb-lg-0">
              <li className="nav-item">
                <Nav.Link
                  as={Link}
                  to="/options"
                  className="nav-link text-white"
                >
                  <i className="mx-1 mb-1 bi bi-gear-fill" />
                  Seçenekler
                </Nav.Link>
              </li>
              <li className="nav-item">
                <Nav.Link
                  as="button"
                  className="nav-link text-white"
                  onClick={handleLogout}
                >
                  <i className="mx-1 mb-1 bi bi-door-closed-fill" />
                  Çıkış Yap
                </Nav.Link>
              </li>
            </ul>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}

export default Header;
