import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Modal, Button, Tabs, Tab } from "react-bootstrap";
import InputMask from "react-input-mask";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import AlbumPackageCheckboxes from "../new/AlbumPackageCheckboxes";
import PaymentList from './paymentList';

function UpdatePhotoContractModal({ show, handleClose, data }) {
  // Ana durum kontrolü - hangi modalın görüntüleneceğini belirler
  const [currentStep, setCurrentStep] = useState('update'); // 'update' veya 'payment'
  const [updatedData, setUpdatedData] = useState(null);

  // Modal kapatma işleyicisi
  const handleModalClose = () => {
    // Sayfayı yenilemeden önce tüm state'i sıfırla
    setCurrentStep('update');
    setUpdatedData(null);
    handleClose();
  };

  // Başarılı güncelleme sonrası çağrılan işleyici
  const handleUpdateSuccess = (newData) => {
    setUpdatedData(newData || data);
    setCurrentStep('payment');
    toast.success("Sözleşme başarıyla güncellendi");
  };

  return (
    <>
      {/* Güncelleme modalı veya Ödeme modalını göster */}
      {currentStep === 'update' ? (
        <UpdateForm
          show={show}
          data={data}
          onClose={handleModalClose}
          onUpdateSuccess={handleUpdateSuccess}
        />
      ) : (
        <PaymentModal
          show={show}
          data={updatedData || data}
          onClose={handleModalClose}
        />
      )}

      <ToastContainer position="top-right" autoClose={3000} />
    </>
  );
}

// SADECE GÜNCELLEME İŞLEMİ YAPAN FORM COMPONENT'I
function UpdateForm({ show, data, onClose, onUpdateSuccess }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [paketTutari, setPaketTutari] = useState(0);
  const [extraTutari, setExtraTutari] = useState(0);
  const [toplamTutar, setToplamTutar] = useState(0);
  const formikRef = useRef(null);

  const [initialValues, setInitialValues] = useState({
    damatAdi: '',
    gelinAdi: '',
    irtibatTelefonu: '',
    fotoAlbumDisCekimTarihi: '',
    secilenPaket: [],
    albumPackages: [],
    fotoAlbumEkstra1: false,
    fotoAlbumEkstra2: false,
    fotoAlbumEkstra3: false,
    aciklama: '',
    odemeIndirim: 0,
    odemeToplam: 0
  });

  const priceData = {
    dijitalPaketFiyat: data?.dijitalPaketFiyat || 0,
    goldPaketFiyat: data?.goldPaketFiyat || 0,
    elitPaketFiyat: data?.elitPaketFiyat || 0,
    lovePaketFiyat: data?.lovePaketFiyat || 0,
    hikayePaketFiyat: data?.hikayePaketFiyat || 0,
  };

  useEffect(() => {
    if (show && data) {
      try {
        console.log("Received data:", data);

        // Paketleri analiz et
        const albumPackagesArray = data.albumPackages ?
          (Array.isArray(data.albumPackages) ? data.albumPackages : JSON.parse(data.albumPackages)) :
          [];

        const selectedPackages = albumPackagesArray?.map(pkg => pkg.name) || [];

        // Paket fiyatını bir kere hesapla ve ayarla
        const totalPackagePrice = albumPackagesArray?.reduce((sum, pkg) => sum + Number(pkg.price), 0) || 0;
        console.log("Başlangıç paket tutarı:", totalPackagePrice);
        setPaketTutari(totalPackagePrice);

        // Ekstra tutarları hesapla
        let ekstraTutarHesap = 0;
        if (data.fotoAlbumEkstra1 === 'Klip') ekstraTutarHesap += 1000;
        if (data.fotoAlbumEkstra2 === 'Aile Albümü') ekstraTutarHesap += 750;
        if (data.fotoAlbumEkstra3 === 'Extra Fotoğraf') ekstraTutarHesap += 500;
        setExtraTutari(ekstraTutarHesap);

        // Tarihi düzgün formatla
        const formattedDate = data.fotoAlbumDisCekimTarihi
          ? moment(data.fotoAlbumDisCekimTarihi).format("YYYY-MM-DDTHH:mm")
          : '';

        setInitialValues({
          damatAdi: data.damatAdi || '',
          gelinAdi: data.gelinAdi || '',
          irtibatTelefonu: data.irtibatTelefonu || '',
          fotoAlbumDisCekimTarihi: formattedDate,
          secilenPaket: selectedPackages,
          albumPackages: albumPackagesArray,
          fotoAlbumEkstra1: data.fotoAlbumEkstra1 === 'Klip',
          fotoAlbumEkstra2: data.fotoAlbumEkstra2 === 'Aile Albümü',
          fotoAlbumEkstra3: data.fotoAlbumEkstra3 === 'Extra Fotoğraf',
          aciklama: data.aciklama || '',
          odemeIndirim: data.odemeIndirim || 0,
          odemeToplam: data.odemeToplam || 0
        });

        setLoading(false);
      } catch (err) {
        console.error('Data processing error:', err);
        setError(err.message);
        setLoading(false);
      }
    }
  }, [show, data]);

  // Toplam fiyat hesaplama - sadece paketTutari ve extraTutari değişimlerinde
  useEffect(() => {
    console.log("Fiyat hesaplama:", { paketTutari, extraTutari });
    setToplamTutar(Number(paketTutari) + Number(extraTutari));
  }, [paketTutari, extraTutari]);

  const validationSchema = Yup.object().shape({
    damatAdi: Yup.string().required('Damat adı zorunludur'),
    gelinAdi: Yup.string().required('Gelin adı zorunludur'),
    irtibatTelefonu: Yup.string().required('İrtibat telefonu zorunludur'),
    fotoAlbumDisCekimTarihi: Yup.string().nullable()
  });

  // Paket değişikliklerini işleyen fonksiyon
  const handlePackageChange = (value, isChecked) => {
    console.log("Paket değişikliği:", { value, isChecked });
    setPaketTutari(prevTutar => {
      const newTutar = isChecked
        ? prevTutar + Number(value)
        : prevTutar - Number(value);
      console.log("Yeni paket tutarı:", newTutar);
      return newTutar;
    });
  };

  // Sadece güncelleme işlemi yapan submit fonksiyonu
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      console.log("Sözleşme güncelleme başlatılıyor...");
      console.log("Güncellenecek değerler:", { values, paketTutari, toplamTutar });

      // Tarih formatını ISO string'e dönüştür
      const formattedDate = values.fotoAlbumDisCekimTarihi
        ? moment(values.fotoAlbumDisCekimTarihi).toISOString()
        : null;

      // Paket bilgilerini güncelleme
      let packageData = values.secilenPaket?.map(paketName => ({
        name: paketName,
        price: Number(paketTutari / (values.secilenPaket.length || 1)) // Toplam fiyatı paket sayısına böl
      })) || [];

      const response = await fetch(process.env.API_URL + `/photoContracts/update/${data.id}`, {
        method: "PATCH",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: data.id,
          data: {
            ...values,
            fotoAlbumDisCekimTarihi: formattedDate,
            secilenPaket: values.secilenPaket?.join("|") || "",
            secilenPaketFiyat: Number(paketTutari) || 0,
            albumPackages: packageData,
            fotoAlbumEkstra1: values.fotoAlbumEkstra1 ? "Klip" : null,
            fotoAlbumEkstra2: values.fotoAlbumEkstra2 ? "Aile Albümü" : null,
            fotoAlbumEkstra3: values.fotoAlbumEkstra3 ? "Extra Fotoğraf" : null,
            odemeToplam: toplamTutar // Toplam tutarın doğru hesaplanmış halini gönder
          },
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Güncelleme başarısız");
      }

      const updatedData = await response.json();
      console.log("Sözleşme güncelleme başarılı!", updatedData);

      // Başarılı güncelleme sonrasında ödeme adımına geç
      onUpdateSuccess(updatedData);
    } catch (err) {
      console.error("Güncelleme hatası:", err);
      toast.error("Güncelleme sırasında bir hata oluştu: " + err.message);
    } finally {
      setSubmitting(false);
    }
  };

  const calculatePaket = (paketAdi, checked) => {
    console.log("Manuel paket hesaplama:", { paketAdi, checked });
    // Paket fiyatı hesaplama fonksiyonu
    const paketFiyati = priceData[`${paketAdi.toLowerCase().replace(/\s+/g, "")}PaketFiyat`] || 0;

    if (checked) {
      setPaketTutari(prev => prev + Number(paketFiyati));
    } else {
      setPaketTutari(prev => prev - Number(paketFiyati));
    }
  };

  if (loading) {
    return (
      <Modal show={show} onHide={onClose}>
        <Modal.Body>
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Yükleniyor...</span>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  if (error) {
    return (
      <Modal show={show} onHide={onClose}>
        <Modal.Body>
          <div className="alert alert-danger">
            Hata: {error}
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  const isAlbumPackagesEmpty = !data.albumPackages || data.albumPackages.length === 0;

  return (
    <Modal show={show} onHide={onClose} size="lg">
      <Modal.Header className="border-0">
        <Modal.Title>Sözleşme Güncelle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
          innerRef={formikRef}
        >
          {({ values, setFieldValue, errors, touched, isSubmitting }) => (
            <Form>
              {/* Müşteri Bilgileri Bölümü */}
              <div className="container shadow-sm p-4 mb-4 border bg-white rounded-4">
                <div className="d-flex justify-content-between align-items-center mb-4 pb-2 border-bottom">
                  <h5 className="m-0">Müşteri Bilgileri</h5>
                  <span className="badge bg-primary px-4 py-2 fs-6">Sözleşme Kodu: {data.contractID}</span>
                </div>
                <div className="row g-3">
                  <div className="col-md-4">
                    <label htmlFor="damatAdi" className="form-label">Damat Adı</label>
                    <Field
                      type="text"
                      className={`form-control ${errors.damatAdi && touched.damatAdi ? 'is-invalid' : ''}`}
                      id="damatAdi"
                      name="damatAdi"
                    />
                    <ErrorMessage name="damatAdi" component="div" className="invalid-feedback" />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="gelinAdi" className="form-label">Gelin Adı</label>
                    <Field
                      type="text"
                      className={`form-control ${errors.gelinAdi && touched.gelinAdi ? 'is-invalid' : ''}`}
                      id="gelinAdi"
                      name="gelinAdi"
                    />
                    <ErrorMessage name="gelinAdi" component="div" className="invalid-feedback" />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="irtibatTelefonu" className="form-label">İrtibat Telefonu</label>
                    <Field name="irtibatTelefonu">
                      {({ field }) => (
                        <InputMask
                          {...field}
                          mask="(999) 999-9999"
                          className={`form-control ${errors.irtibatTelefonu && touched.irtibatTelefonu ? 'is-invalid' : ''}`}
                        />
                      )}
                    </Field>
                    <ErrorMessage name="irtibatTelefonu" component="div" className="invalid-feedback" />
                  </div>
                  <div className="col-12">
                    <label htmlFor="fotoAlbumDisCekimTarihi" className="form-label">
                      Dış Çekim Tarihi
                    </label>
                    <Field name="fotoAlbumDisCekimTarihi">
                      {({ field, form }) => (
                        <input
                          type="datetime-local"
                          className={`form-control ${errors.fotoAlbumDisCekimTarihi && touched.fotoAlbumDisCekimTarihi ? 'is-invalid' : ''}`}
                          id="fotoAlbumDisCekimTarihi"
                          value={field.value || ''}
                          onChange={(e) => {
                            const date = e.target.value;
                            form.setFieldValue('fotoAlbumDisCekimTarihi', date);
                          }}
                        />
                      )}
                    </Field>
                    <ErrorMessage name="fotoAlbumDisCekimTarihi" component="div" className="invalid-feedback" />
                  </div>
                </div>
              </div>

              {/* Albüm Paketi Bölümü */}
              <div className="container shadow-sm p-4 mb-4 border bg-white rounded-4">
                <div className="d-flex justify-content-between align-items-center mb-4 pb-2 border-bottom">
                  <h5 className="m-0">Albüm Paketi</h5>
                </div>
                {isAlbumPackagesEmpty ? (
                  <div className="row px-5 justify-content-center">
                    <div className="form-check col-lg-auto me-3 my-auto pt-3 pe-lg-0">
                      <Field
                        type="checkbox"
                        className="form-check-input"
                        id="dijitalPaket"
                        name="secilenPaket"
                        onClick={(e) => {
                          calculatePaket("dijital", e.target.checked);
                        }}
                        value="Dijital Paket"
                      />
                      <label
                        className="form-check-label ms-2 fw-bold"
                        htmlFor="dijitalPaket"
                      >
                        Dijital Paket
                      </label>
                      <label
                        className={`form-check-label ms-2 ${priceData.dijitalPaketFiyat !== data.dijitalPaketFiyat
                            ? "text-warning"
                            : ""
                          }`}
                        htmlFor="dijitalPaket"
                      >
                        {priceData.dijitalPaketFiyat !== data.dijitalPaketFiyat
                          ? priceData.dijitalPaketFiyat
                          : data.dijitalPaketFiyat}
                        ₺
                      </label>
                    </div>
                    <div className="form-check col-lg-auto me-3 my-auto pt-3 pe-lg-0">
                      <Field
                        type="checkbox"
                        className="form-check-input"
                        id="goldPaket"
                        name="secilenPaket"
                        onClick={(e) => {
                          calculatePaket("gold", e.target.checked);
                        }}
                        value="Gold Paket"
                      />
                      <label
                        className="form-check-label ms-2 fw-bold"
                        htmlFor="goldPaket"
                      >
                        Gold Paket
                      </label>
                      <label
                        className={`form-check-label ms-2 ${priceData.goldPaketFiyat !== data.goldPaketFiyat
                            ? "text-warning"
                            : ""
                          }`}
                        htmlFor="goldPaket"
                      >
                        {priceData.goldPaketFiyat !== data.goldPaketFiyat
                          ? priceData.goldPaketFiyat
                          : data.goldPaketFiyat}
                        ₺
                      </label>
                    </div>
                    <div className="form-check col-lg-auto me-3 my-auto pt-3 pe-lg-0">
                      <Field
                        type="checkbox"
                        className="form-check-input"
                        id="elitPaket"
                        name="secilenPaket"
                        onClick={(e) => {
                          calculatePaket("elit", e.target.checked);
                        }}
                        value="Elit Paket"
                      />
                      <label
                        className="form-check-label ms-2 fw-bold"
                        htmlFor="elitPaket"
                      >
                        Elit Paket
                      </label>
                      <label
                        className={`form-check-label ms-2 ${priceData.elitPaketFiyat !== data.elitPaketFiyat
                            ? "text-warning"
                            : ""
                          }`}
                        htmlFor="elitPaket"
                      >
                        {priceData.elitPaketFiyat !== data.elitPaketFiyat
                          ? priceData.elitPaketFiyat
                          : data.elitPaketFiyat}
                        ₺
                      </label>
                    </div>
                    <div className="form-check col-lg-auto me-3 my-auto pt-3 pe-lg-0">
                      <Field
                        type="checkbox"
                        className="form-check-input"
                        id="lovePaket"
                        name="secilenPaket"
                        onClick={(e) => {
                          calculatePaket("love", e.target.checked);
                        }}
                        value="Love Paket"
                      />
                      <label
                        className="form-check-label ms-2 fw-bold"
                        htmlFor="lovePaket"
                      >
                        Love Paket
                      </label>
                      <label
                        className={`form-check-label ms-2 ${priceData.lovePaketFiyat !== data.lovePaketFiyat
                            ? "text-warning"
                            : ""
                          }`}
                        htmlFor="lovePaket"
                      >
                        {priceData.lovePaketFiyat !== data.lovePaketFiyat
                          ? priceData.lovePaketFiyat
                          : data.lovePaketFiyat}
                        ₺
                      </label>
                    </div>
                    <div className="form-check col-lg-auto me-3 my-auto pt-3 pe-lg-0">
                      <Field
                        type="checkbox"
                        className="form-check-input"
                        id="hikayePaket"
                        name="secilenPaket"
                        onClick={(e) => {
                          calculatePaket("hikaye", e.target.checked);
                        }}
                        value="Hikaye Paket"
                      />
                      <label
                        className="form-check-label ms-2 fw-bold"
                        htmlFor="hikayePaket"
                      >
                        Hikaye Paket
                      </label>
                      <label
                        className={`form-check-label ms-2 ${priceData.hikayePaketFiyat !== data.hikayePaketFiyat
                            ? "text-warning"
                            : ""
                          }`}
                        htmlFor="hikayePaket"
                      >
                        {priceData.hikayePaketFiyat !== data.hikayePaketFiyat
                          ? priceData.hikayePaketFiyat
                          : data.hikayePaketFiyat}
                        ₺
                      </label>
                    </div>
                  </div>
                ) : (
                  <AlbumPackageCheckboxes
                    initialData={initialValues.albumPackages}
                    onPackageChange={handlePackageChange}
                  />
                )}
              </div>

              {/* Albüm Bilgileri Bölümü */}
              <div className="container shadow-sm p-4 mb-4 border bg-white rounded-4">
                <div className="d-flex justify-content-between align-items-center mb-4 pb-2 border-bottom">
                  <h5 className="m-0">Albüm Bilgileri</h5>
                </div>
                <div className="row g-4">
                  <div className="col-md-4">
                    <div
                      className="form-check d-flex justify-content-between align-items-center p-3 border rounded"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        const newValue = !values.fotoAlbumEkstra1;
                        setFieldValue('fotoAlbumEkstra1', newValue);
                        setExtraTutari(prev => newValue ? prev + 1000 : prev - 1000);
                      }}
                    >
                      <div>
                        <Field
                          type="checkbox"
                          className="form-check-input me-2"
                          id="fotoAlbumEkstra1"
                          name="fotoAlbumEkstra1"
                          onClick={(e) => e.stopPropagation()}
                        />
                        <label className="form-check-label" style={{ cursor: 'pointer' }}>
                          Klip
                        </label>
                      </div>
                      <span className="badge bg-secondary">1000₺</span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div
                      className="form-check d-flex justify-content-between align-items-center p-3 border rounded"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        const newValue = !values.fotoAlbumEkstra2;
                        setFieldValue('fotoAlbumEkstra2', newValue);
                        setExtraTutari(prev => newValue ? prev + 750 : prev - 750);
                      }}
                    >
                      <div>
                        <Field
                          type="checkbox"
                          className="form-check-input me-2"
                          id="fotoAlbumEkstra2"
                          name="fotoAlbumEkstra2"
                          onClick={(e) => e.stopPropagation()}
                        />
                        <label className="form-check-label" style={{ cursor: 'pointer' }}>
                          Aile Albümü
                        </label>
                      </div>
                      <span className="badge bg-secondary">750₺</span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div
                      className="form-check d-flex justify-content-between align-items-center p-3 border rounded"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        const newValue = !values.fotoAlbumEkstra3;
                        setFieldValue('fotoAlbumEkstra3', newValue);
                        setExtraTutari(prev => newValue ? prev + 500 : prev - 500);
                      }}
                    >
                      <div>
                        <Field
                          type="checkbox"
                          className="form-check-input me-2"
                          id="fotoAlbumEkstra3"
                          name="fotoAlbumEkstra3"
                          onClick={(e) => e.stopPropagation()}
                        />
                        <label className="form-check-label" style={{ cursor: 'pointer' }}>
                          Extra Fotoğraf
                        </label>
                      </div>
                      <span className="badge bg-secondary">500₺</span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Açıklama Bölümü */}
              <div className="container shadow-sm p-4 mb-4 border bg-white rounded-4">
                <div className="d-flex justify-content-between align-items-center mb-4 pb-2 border-bottom">
                  <h5 className="m-0">Açıklama</h5>
                  <div className="d-flex gap-3">
                    <span className="badge bg-success px-3 py-2 fs-6">Toplam: {toplamTutar}₺</span>
                  </div>
                </div>
                <div className="row g-3">
                  <div className="col-md-12">
                    <label htmlFor="aciklama" className="form-label">Açıklama</label>
                    <Field
                      as="textarea"
                      className="form-control"
                      id="aciklama"
                      name="aciklama"
                      rows="3"
                    />
                  </div>
                </div>
              </div>

              {/* Bilgi Notu */}
              <div className="alert alert-warning mb-4">
                <i className="bi bi-info-circle me-2"></i>
                Güncelleme işlemi tamamlandıktan sonra ödeme işlemleri yapabilirsiniz.
              </div>

              {/* Butonlar */}
              <div className="d-flex justify-content-end">
                <Button
                  variant="secondary"
                  onClick={onClose}
                  className="me-2"
                  type="button"
                  disabled={isSubmitting}
                >
                  İptal
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Güncelleniyor...' : 'Güncelle ve Devam Et'}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

// SADECE ÖDEME İŞLEMİ YAPAN MODAL COMPONENT'I
function PaymentModal({ show, data, onClose }) {
  const [activeTab, setActiveTab] = useState('addPayment');
  const [odemeTuru, setOdemeTuru] = useState('Nakit');
  const [odemeAlinan, setOdemeAlinan] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Ödeme ekleme
  const handleOdemeEkle = async (e) => {
    // Eğer bu bir form submit event'i ise, işlemi durdur
    if (e && e.type === 'submit') {
      console.log("Form submit event'i algılandı, ödeme işlemi durduruldu");
      return;
    }

    // Sadece click event'i ise veya doğrudan çağırıldıysa devam et
    console.log("Ödeme ekleme click işlemi başlatılıyor...", { eventType: e?.type });

    if (!odemeAlinan || Number(odemeAlinan) <= 0) {
      toast.error('Lütfen geçerli bir ödeme tutarı giriniz');
      return;
    }

    setIsSubmitting(true);

    try {
      console.log("Ödeme API çağrısı başlatılıyor...");

      const response = await fetch(process.env.API_URL + '/photoContracts/addPay', {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: data.id,
          data: {
            odemeTuru: odemeTuru,
            odemeAlinan: odemeAlinan
          },
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Ödeme eklenemedi");
      }

      console.log("Ödeme ekleme başarılı!");
      toast.success('Ödeme başarıyla eklendi!');
      setOdemeAlinan(''); // Input alanını temizle

      // Ödeme listesi sekmesine geç
      setActiveTab('paymentList');
    } catch (error) {
      console.error("Ödeme ekleme hatası:", error);
      toast.error('Ödeme eklenirken hata oluştu: ' + error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal show={show} onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          Ödeme İşlemleri - {data.gelinAdi} & {data.damatAdi}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs
          activeKey={activeTab}
          onSelect={(k) => setActiveTab(k)}
          className="mb-4"
        >
          <Tab eventKey="addPayment" title="Ödeme Ekle">
            <div className="p-3">
              {/* onClick veya submit tetiklenince bu form içinde kalsın */}
              <form onSubmit={(e) => {
                e.preventDefault(); // Form submit'i engelle
                console.log("Ödeme formu submit engellendi");
                return false;
              }}>
                <div className="mb-3">
                  <label htmlFor="odemeTuruSelect" className="form-label">Ödeme Türü</label>
                  <select
                    className="form-select"
                    id="odemeTuruSelect"
                    value={odemeTuru}
                    onChange={(e) => setOdemeTuru(e.target.value)}
                    disabled={isSubmitting}
                  >
                    <option value="Nakit">Nakit</option>
                    <option value="Havale">Havale</option>
                    <option value="İskonto">İskonto</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="odemeAlinanInput" className="form-label">Tutar</label>
                  <InputMask
                    type="text"
                    className="form-control"
                    id="odemeAlinanInput"
                    mask="9999999999"
                    maskChar={""}
                    placeholder="Tutar giriniz"
                    value={odemeAlinan}
                    onChange={(e) => setOdemeAlinan(e.target.value)}
                    disabled={isSubmitting}
                    onKeyPress={(e) => {
                      // Enter tuşuna basılırsa, sayfa yenilemesini engelle
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        return false;
                      }
                    }}
                  />
                </div>
                <Button
                  variant="warning"
                  onClick={(e) => {
                    e.preventDefault(); // Form submit'i engelle
                    e.stopPropagation(); // Event bubbling'i engelle
                    handleOdemeEkle(e); // Event'i geçir ki kontrol edilebilsin
                  }}
                  type="button" // Kesinlikle submit butonu olmamalı
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Ekleniyor...' : 'Ödeme Ekle'}
                </Button>
              </form>
            </div>
          </Tab>
          <Tab eventKey="paymentList" title="Ödeme Geçmişi">
            <PaymentList photoContractId={data.id} />
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Kapat
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default UpdatePhotoContractModal;
