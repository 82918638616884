import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Header from "./ui/Header";
import Options from "./options/Options";

import BridalContracts from "./bridal/BridalContracts";
import NewBridalContract from "./bridal/new/NewBridalContract";
import NewBridalContractSuccess from "./bridal/new/NewBridalContractSuccess";

import ListPrintCanvas from "./printCanvases/list/ListPrintCanvas";
import NewBridalPrintCanvas from "./printCanvases/new/NewBridalPrintCanvas";
import NewPhotoPrintCanvas from "./printCanvases/new/NewPhotoPrintCanvas";

import PhotoContracts from "./photo/PhotoContracts";
import NewPhotoContract from "./photo/new/NewPhotoContract";
import NewPhotoContractSuccess from "./photo/new/NewPhotoContractSuccess";

import Calendar from "./calendar/Calendar";
import Login from "./login/Login";
import { PrintProvider } from "./printCanvases/contextWrapper/PrintContext";
import LiteBridalContracts from "./bridal-lite/LiteBridalContracts";
import NewLiteBridalContract from "./bridal-lite/new/NewLiteBridalContract";
import NewLiteBridalContractSuccess from "./bridal-lite/new/NewLiteBridalContractSuccess";
import NewLiteBridalPrintCanvas from "./printCanvases/new/NewLiteBridalPrintCanvas";
import WeddingContracts from "./wedding/WeddingContracts";
import NewWeddingContract from "./wedding/new/NewWeddingContract";
import NewWeddingContractSuccess from "./wedding/new/NewWeddingContractSuccess";
import NewWeddingPrintCanvas from "./printCanvases/new/NewWeddingPrintCanvas";
import ExtraPackages from "./extras/ExtraPackages";
import DayEnd from "./dayend/DayEnd";

function App() {
  const [hasAuth, setAuth] = useState(false);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [formDirty, setFormDirty] = useState(false);

  useEffect(() => {
    fetch(process.env.API_URL + "/v2/auth/me", {
      method: "GET",
      credentials: "include",
    })
      .then(async (response) => {
        if (response.ok) {
          const data = await response.json();
          if (data.isAuthenticated) {
            setAuth(true);
            setUser(data.user);
          } else {
            setAuth(false);
            setUser(null);
            Cookies.remove("authToken", { path: "" });
          }
        } else {
          setAuth(false);
          setUser(null);
          Cookies.remove("authToken", { path: "" });
        }
      })
      .catch(() => {
        setAuth(false);
        setUser(null);
        Cookies.remove("authToken", { path: "" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleAuth = () => {
    setAuth(true);
  };

  const handleLogout = () => {
    fetch(process.env.API_URL + "/v2/auth/logout", {
      method: "POST",
      credentials: "include",
    })
      .then(async (response) => {
        if (response.ok) {
          setAuth(false);
          setUser(null);
          Cookies.remove("authToken", { path: "/" });
        }
      })
      .catch(() => {
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh", width: "100vw" }}
      >
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Yükleniyor...</span>
        </div>
      </div>
    );
  }

  return (
    <Router>
      <PrintProvider>
        {hasAuth && <Header handleLogout={handleLogout} />}
        <Routes>
          {!hasAuth ? (
            <>
              <Route
                path="/login"
                element={<Login onRedirect={handleAuth} />}
              />
              <Route path="*" element={<Navigate to="/login" />} />
            </>
          ) : (
            <>
              <Route path="/" element={<Navigate to="/bridal-contracts" />} />
              <Route path="/bridal-contracts">
                <Route
                  index
                  element={<BridalContracts handleLogout={handleLogout} />}
                />
                <Route
                  path="new"
                  element={<NewBridalContract setFormDirty={setFormDirty} />}
                />
                <Route path="success" element={<NewBridalContractSuccess />} />
              </Route>

              <Route path="/photo-contracts">
                <Route index element={<PhotoContracts />} />
                <Route
                  path="new"
                  element={<NewPhotoContract setFormDirty={setFormDirty} />}
                />
                <Route path="success" element={<NewPhotoContractSuccess />} />
              </Route>

              <Route path="/lite-bridal-contracts">
                <Route index element={<LiteBridalContracts />} />
                <Route
                  path="new"
                  element={
                    <NewLiteBridalContract setFormDirty={setFormDirty} />
                  }
                />
                <Route
                  path="success"
                  element={<NewLiteBridalContractSuccess />}
                />
              </Route>

              <Route path="/wedding-contracts">
                <Route index element={<WeddingContracts />} />
                <Route
                  path="new"
                  element={<NewWeddingContract setFormDirty={setFormDirty} />}
                />
                <Route path="success" element={<NewWeddingContractSuccess />} />
              </Route>

              <Route path="/calendar" element={<Calendar />} />
              <Route path="/extras" element={<ExtraPackages />} />

              <Route path="/dayreport" element={<DayEnd />} />

              <Route
                path="/options"
                element={<Options setFormDirty={setFormDirty} />}
              />
              <Route path="*" element={<Navigate to="/" />} />
            </>
          )}
        </Routes>
        {hasAuth && (
          <>
            <ListPrintCanvas />
            <NewBridalPrintCanvas />
            <NewPhotoPrintCanvas />
            <NewLiteBridalPrintCanvas />
            <NewWeddingPrintCanvas />
          </>
        )}
      </PrintProvider>
    </Router>
  );
}

export default App;
