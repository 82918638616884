import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Modal, Button } from 'react-bootstrap';

const DayEnd = () => {
  const [selectedDate, setSelectedDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [reportHtml, setReportHtml] = useState('');

  const handlePrint = () => {
    console.log("Printing HTML:", reportHtml);
    const printWindow = window.open('', '', 'height=600,width=800');
    printWindow.document.open();
    printWindow.document.write(reportHtml);
    printWindow.document.close();
    printWindow.focus();
    setTimeout(() => {
      printWindow.print();
    }, 500);
  };

  const handleDateReport = async () => {
    if (!selectedDate) {
      toast.error('Lütfen bir tarih seçin');
      return;
    }

    setLoading(true);
    try {
      console.log("Fetching report for date:", selectedDate);
      const response = await fetch(process.env.API_URL + "/report/dayReport", {
        method: "POST",
        credentials: "include",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ date: selectedDate })
      });

      console.log("Response status:", response.status);
      if (!response.ok) {
        throw new Error('Rapor alınırken bir hata oluştu');
      }

      const contentType = response.headers.get('content-type');
      let result;
      if (contentType && contentType.includes('application/json')) {
        result = await response.json();
      } else {
        result = await response.text();
      }

      console.log("Received response:", result);

      if (result) {
        setReportHtml(result);
        setShowModal(true);
      } else {
        throw new Error('Rapor içeriği bulunamadı');
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Rapor alınırken bir hata oluştu");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-4">
      <div className="card shadow-sm">
        <div className="card-header bg-primary text-white">
          <h5 className="mb-0">Gün Sonu Raporu</h5>
        </div>
        <div className="card-body">
          <div className="row align-items-end">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="dateInput" className="form-label">Tarih Seçin</label>
                <input
                  type="date"
                  className="form-control"
                  id="dateInput"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <button
                className="btn btn-primary w-100"
                onClick={handleDateReport}
                disabled={loading || !selectedDate}
              >
                {loading ? (
                  <>
                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                    Rapor Alınıyor...
                  </>
                ) : (
                  <>
                    <i className="bi bi-file-earmark-text me-2"></i>
                    Rapor Al
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        dialogClassName="modal-90w"
        aria-labelledby="report-modal"
        centered
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          {reportHtml ? (
            <div dangerouslySetInnerHTML={{ __html: reportHtml }} />
          ) : (
            <div className="text-center py-4">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Yükleniyor...</span>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Kapat
          </Button>
          <Button variant="primary" onClick={handlePrint}>
            <i className="bi bi-printer me-2"></i>Yazdır
          </Button>
        </Modal.Footer>
      </Modal>

      <style jsx>{`
        .modal-90w {
          width: 90%;
          max-width: 90%;
        }
      `}</style>
    </div>
  );
};

export default DayEnd;