import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";

const ExtraPackages = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [reload, setReload] = useState(false);
  const [updatedValues, setUpdatedValues] = useState({});
  const [todaySalesData, setTodaySalesData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(process.env.API_URL + "/extraPackages/get", {
          credentials: "include",
          headers: {
            "Content-Type": "application/json"
          }
        });
        if (!response.ok) throw new Error('Network response was not ok');
        const result = await response.json();
        setData(result);
        setError(null);
      } catch (err) {
        setError(err.message);
        toast.error("Veri yüklenirken bir hata oluştu");
      } finally {
        setLoading(false);
        setReload(false);
      }
    };

    const fetchTodaySales = async () => {
      try {
        const response = await fetch(process.env.API_URL + "/extraPackages/todaySales", {
          credentials: "include",
          headers: {
            "Content-Type": "application/json"
          }
        });
        if (!response.ok) throw new Error('Network response was not ok');
        const result = await response.json();
        setTodaySalesData(result);
      } catch (err) {
        console.error("Günlük satış verileri yüklenirken hata:", err);
        toast.error("Günlük satış verileri yüklenirken bir hata oluştu");
      }
    };

    fetchData();
    fetchTodaySales();
  }, [reload]);

  const handleSale = async (id) => {
    if (window.confirm('Satış yapmak istediğinize emin misiniz?')) {
      try {
        const response = await fetch(
          process.env.API_URL + "/extraPackages/sale/" + id,
          {
            method: "POST",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              nakit: updatedValues[id]?.nakit || 0,
              havale: updatedValues[id]?.havale || 0,
              name: updatedValues[id]?.name || data?.name
            })
          }
        );

        if (response.ok) {
          toast.success("Satış başarıyla yapıldı");
          setReload(true);
        } else {
          const errorData = await response.json();
          toast.error(errorData.message || "Satış yapılırken bir hata oluştu");
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("Satış yapılırken bir hata oluştu");
      }
    }
  };

const handleDelete= async (id)=>{

  try{  
    
    if(window.confirm("Kayıt silinecek eminmisiniz1?")){
 
      const result=await fetch(process.env.API_URL+"/extraPackages/delete/"+id,{
        method:"DELETE",
        credentials:'include'

      });

      if(result.ok){
        toast.success("Veri silindi");
        setReload(true);
      }else{
        const errorData=await result.json();
        toast.error(errorData.message || "Veri silinirken bir hata oluştu");
      }
    }

  } catch(error){
    console.error("Error:", error);
    toast.error("Veri silinirken bir hata oluştu");
  }
}

  if (loading) return <div className="text-center mt-4">Yükleniyor...</div>;
  if (error) return <div className="alert alert-danger m-3">Hata: {error}</div>;
  if (!data) return <div className="alert alert-warning m-3">Veri bulunamadı</div>;

  return (
    <div className="container">
      <div className="card mt-3 shadow-sm">
        <div className="card-body d-flex flex-row justify-content-between align-items-center">
          <div>
            <h5 className="card-title">
              Ürün Adı: 
              <input
                type="text"
                className="form-control mt-2"
                defaultValue={data.name}
                onChange={(e) => {
                  const newValues = { ...updatedValues };
                  newValues[data.id] = { ...newValues[data.id], name: e.target.value };
                  setUpdatedValues(newValues);
                }}
              />
            </h5>
            <div className="row g-2">
              <div className="col-md-6">
                <input
                  type="number"
                  className="form-control mb-2"
                  value={updatedValues[data.id]?.nakit || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === "" || /^\d*$/.test(value)) {
                      const newValues = { ...updatedValues };
                      newValues[data.id] = { ...newValues[data.id], nakit: value };
                      setUpdatedValues(newValues);
                    }
                  }}
                  min="0"
                  id="nakit"
                  name="nakit"
                  placeholder="Nakit Tutarı"
                />
              </div>
              <div className="col-md-6">
                <input
                  type="number"
                  className="form-control mb-2"
                  value={updatedValues[data.id]?.havale || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === "" || /^\d*$/.test(value)) {
                      const newValues = { ...updatedValues };
                      newValues[data.id] = { ...newValues[data.id], havale: value };
                      setUpdatedValues(newValues);
                    }
                  }}
                  min="0"
                  id="havale"
                  name="havale"
                  placeholder="Havale Tutarı"
                />
              </div>
            </div>
          </div>
          <div>
            <button
              className="btn btn-success me-2"
              onClick={() => handleSale(data.id)}
            >
              Satış Yap
            </button>
          </div>
        </div>
      </div>

      {/* Günlük Satışlar Tablosu */}
      <div className="card mt-4 shadow-sm">
        <div className="card-header bg-primary text-white">
          <h5 className="mb-0">Günlük Satışlar</h5>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Ürün Adı</th>
                  <th>Nakit</th>
                  <th>Havale</th>
                </tr>
              </thead>
              <tbody>
                {todaySalesData.map((sale) => (
                  <tr key={sale.id}>
                    <td>{sale.id}</td>
                    <td>{sale.name}</td>
                    <td>{sale.nakit}₺</td>
                    <td>{sale.havale}₺</td>
                    <td><button className="btn btn-danger" onClick={()=>handleDelete(sale.id)}>Sil</button></td>
                  </tr>
                ))}
                {todaySalesData.length === 0 && (
                  <tr>
                    <td colSpan="4" className="text-center">
                      Bugün henüz satış yapılmamış
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={2500}
        limit={3}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition:Flip
      />
    </div>
  );
};

export default ExtraPackages;