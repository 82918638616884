import React, { Fragment, useRef, useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import moment from "moment";
import { Button, Dropdown, ButtonGroup } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputMask from "react-input-mask";
import UpdateBridalContractModal from "./update/UpdateBridalContractModal";
import ContractHistoryModal from "../modals/ContractHistoryModal";

import { usePrintContext } from "../printCanvases/contextWrapper/PrintContext";
import Pagination from "../../Utils/Pagination";

function BridalContracts({ handleLogout }) {
  const { setPrintData, switchToPrintPage, setShouldPrint } = usePrintContext();
  const {
    setSecondPrintData,
    setFazlaTutar,
    setListCurrentPage,
    setPrintContext,
    switchToSecondPrintPage,
    setSecondShouldPrint
  } = usePrintContext();

  const handlePrintButtonClick = (data) => {
    setPrintData(data);

    setShouldPrint("bridal");

    switchToPrintPage();
  };
  const navigate = useNavigate();
  const handleSecondPrintButtonClick = (data, context) => {
    if (fetchStatus) {
      toast.warning(
        "Devam etmeden önce sözleşmelerin yüklenmesini bekleyiniz!",
        {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        }
      );
    } else if (data.length > 0) {
      setSecondPrintData(data);

      setFazlaTutar(document.getElementById("fazlaTutar").value);

      setPrintContext(context);

      setListCurrentPage(currentPage.current);

      setSecondShouldPrint(true);

      switchToSecondPrintPage();
    } else {
      toast.error("Sözleşme listesi boş!", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    }
  };



  
  const handle3DayEnd = async () => {
    try {
      const response = await fetch(process.env.API_URL + "/report/dayReport", {
        method: "GET",
        credentials: "include",
      });
      const result = await response.json();
      
      if (result && result.html) {
        // Create a new window for printing
        const printWindow = window.open('', '', 'height=600,width=800');
        printWindow.document.write('<html><head><title>Yazdırma Önizleme</title>');
        
        // Add any necessary styles
        printWindow.document.write(`
          <style>
            body { font-family: Arial, sans-serif; }
            @media print {
              body { margin: 0; padding: 20px; }
            }
          </style>
        `);
        
        printWindow.document.write('</head><body>');
        printWindow.document.write(result.html);
        printWindow.document.write('</body></html>');
        
        // Wait for content to load
        printWindow.document.close();
        printWindow.focus();
        
        // Wait for resources to load before printing
        setTimeout(() => {
          printWindow.print();
        }, 500);
      }
    } catch (error) {
      console.error("Error fetching report:", error);
      toast.error("Rapor alınırken bir hata oluştu");
    }
  }

  /*
 const handle3DayEnd = async () => {
  navigate("/dayreport");
 }
*/
  const [data, setData] = useState(false);
  const [, setContractsUpdates] = useState("");
  


  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [fetchStatus, setFetch] = useState(false);

  const [activeDocumentId, setActiveDocumentId] = useState();

  const [onlyWithPaymentsLeft, setOnlyWithPaymentsLeft] = useState();

  const [filterOption, setFilterOption] = useState("all");
  const [advancedSearch, setAdvancedSearch] = useState(false);

  const limitIndex = useRef(20);
  const documentCount = useRef(0);
  const totalDocumentCount = useRef(0);
  const currentPage = useRef(1);
  const totalPages = useRef(1);
  const currentTab = useRef("all");
  const orderBy = useRef("");

  const phoneInput = useRef();
  const idInput = useRef();

  const [gelinlikModeliFilter, setGelinlikModeliFilter] = useState("Hepsi");
  const [gelinlikTeslimatiFilter, setGelinlikTeslimatiFilter] =useState("Hepsi");
  const [disCekimFilter, setDisCekimFilter] = useState("Hepsi");
  const [updateModal, setUpdateModal] = useState(false);
  const activeDocument = useRef();

  const toggleUpdateModal = (thisDocument) => {
    activeDocument.current = thisDocument;
    setUpdateModal(!updateModal);
  };

  const closeModal = () => {
    setUpdateModal(!updateModal);
    setFetch(true);
  };

  const [historyModal, setHistoryModal] = useState(false);
  const activeDocumentHistory = useRef();

  const toggleHistoryModal = (data) => {
    activeDocumentHistory.current = data;
    setHistoryModal(!historyModal);
  };
 
  useEffect(() => {
    fetch(
      process.env.API_URL +
        "/bridalContracts?" +
        new URLSearchParams({
          page:
            currentPage.current > totalPages.current
              ? totalPages.current
              : currentPage.current,
          limit: 20,
          orderBy:  "contractID.desc",
          filters: JSON.stringify({
            musteriAdi: "",
            musteriTelefonu: "",
            contractID: "",
            startDate:  "",
            endDate: "",
            gelinlikFiltre:  "",
            kinalikFiltre:  "",
            gelinlikTeslimatFiltresi:"",
            disCekimFiltresi: "",
            duvakFiltre:false,
            tacFiltre: false,
            terzilikFiltre: false,
            albumFiltre: false,
            onBinFiltre: false,
            filterOption: "today",
            tab: "all",
          }),
        }),
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((response) => {
        if (response.ok) {
          setError(null);
          return response.json();
        } else {
          throw response;
        }
      })
      .then((actualData) => {
        setData(actualData.payload);

        setContractsUpdates(actualData.contractsUpdates);
        
        documentCount.current = actualData.amount;
        totalDocumentCount.current = actualData.totalAmount;
        totalPages.current = actualData.totalPages;

        if (currentPage.current > totalPages.current) {
          currentPage.current = totalPages.current;
        }
      })
      .catch((err) => {
        if (err.status === 401) {
          handleLogout();
          setError(
            "401 - Kullanıcı yetkileri geçersiz, lütfen tekrar giriş yapınız."
          );
        } else if (err.message === "Failed to fetch") {
          setError("Veritabanıyla iletişim kurulamadı.");
        } else
          setError(
            "Beklenmedik bir durum oluştu, lütfen biraz bekleyip tekrar deneyin."
          );
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
          setFetch(false);
        }, 100);
      });



  },[]);

  useEffect(() => {
    if (fetchStatus) {
      setLoading(true);
      fetch(
        process.env.API_URL +
          "/bridalContracts?" +
          new URLSearchParams({
            page:
              currentPage.current > totalPages.current
                ? totalPages.current
                : currentPage.current,
            limit: limitIndex.current,
            orderBy: orderBy.current || "contractID.asc",
            filters: JSON.stringify({
              musteriAdi: document.forms["detailedSearch"][0].value,
              musteriTelefonu: document.forms[
                "detailedSearch"
              ][1].value.replace(/\D/g, ""),
              contractID:
                document.forms["detailedSearch"][2].value.substring(3),
              startDate: document.forms["detailedSearch"][3].value,
              endDate: document.forms["detailedSearch"][4].value,
              gelinlikFiltre: document.forms["detailedSearch"][8].value,
              kinalikFiltre: document.forms["detailedSearch"][9].value,
              gelinlikTeslimatFiltresi:
                document.forms["detailedSearch"][10].value,
              disCekimFiltresi: document.forms["detailedSearch"][11].value,
              duvakFiltre: document.forms["detailedSearch"][12].checked,
              tacFiltre: document.forms["detailedSearch"][13].checked,
              terzilikFiltre: document.forms["detailedSearch"][14].checked,
              albumFiltre: document.forms["detailedSearch"][15].checked,
              onBinFiltre: document.forms["detailedSearch"][16].checked,
              onlyWithPaymentsLeft: onlyWithPaymentsLeft,
              filterOption: filterOption,
              tab: currentTab.current,
            }),
          }),
        {
          method: "GET",
          credentials: "include",
        }
      )
        .then((response) => {
          if (response.ok) {
            setError(null);
            return response.json();
          } else {
            throw response;
          }
        })
        .then((actualData) => {
          setData(actualData.payload);

          setContractsUpdates(actualData.contractsUpdates);
          
          documentCount.current = actualData.amount;
          totalDocumentCount.current = actualData.totalAmount;
          totalPages.current = actualData.totalPages;

          if (currentPage.current > totalPages.current) {
            currentPage.current = totalPages.current;
          }
        })
        .catch((err) => {
          if (err.status === 401) {
            handleLogout();
            setError(
              "401 - Kullanıcı yetkileri geçersiz, lütfen tekrar giriş yapınız."
            );
          } else if (err.message === "Failed to fetch") {
            setError("Veritabanıyla iletişim kurulamadı.");
          } else
            setError(
              "Beklenmedik bir durum oluştu, lütfen biraz bekleyip tekrar deneyin."
            );
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
            setFetch(false);
          }, 100);
        });
    }
  }, [fetchStatus, onlyWithPaymentsLeft, filterOption, handleLogout]);

  const deleteContract = (id) => {
    if (!fetchStatus) {
      fetch(process.env.API_URL + "/bridalContracts", {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
        }),
      })
        .then((response) => {
          if (response.ok) {
            toast.success("Sözleşme başarıyla silindi!", {
              position: "top-center",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
          } else {
            throw response;
          }
        })
        .catch(() => {
          toast.error("Sözleşme silinirken bir hata meydana geldi", {
            position: "top-center",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        })
        .finally(() => {
          setTimeout(() => {
            setFetch(true);
          }, 100);
        });
    } else {
      toast.error("Devam etmeden önce lütfen biraz bekleyiniz", {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    }
  };

  const renderContracts = () => {
    return data.map((data) => (
      <>
        <tr
          className={`align-middle text-center ${
            data.tab === "cancelled"
              ? "table-secondary"
              : data.tab === "archive"
              ? "table-light"
              : data.tab === "completed"
              ? "table-info"
              : ""
          }`}
          style={{ height: "110px" }}
          key={data.contractID}
          id={`100${data.contractID}`}
        >
          <td className="">
            <Dropdown>
              <Dropdown.Toggle
                className="focus-ring dropdown-toggle"
                variant="primary"
                value={`100${data.contractID}`}
              >
                <i className="bi bi-pencil-fill" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
  
         

                <Dropdown.Item onClick={() => handlePrintButtonClick(data)}>
                  Yazdır
                </Dropdown.Item>
                <Dropdown.Item onClick={() => toggleUpdateModal(data)}>
                  Güncelle
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    toggleHistoryModal(data.id);
                  }}
                >
                  Geçmiş
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    if (activeDocumentId === data.contractID) {
                      setActiveDocumentId("");
                    } else {
                      setActiveDocumentId(data.contractID);
                    }
                  }}
                >
                  Detaylar
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  onClick={() => {
                    if (data.tab !== "archive") {
                      if (
                        data.odemeToplam - data.odemeAlinan > 0 &&
                        data.tab === "active"
                      ) {
                        const confirm = window.confirm(
                          "Kalan ödemesi olan şözleşmeyi iptal etmek istediğinizden emin misiniz?"
                        );
                        if (confirm) {
                          moveContractBetween(data.id, data.tab, "archive");
                        }
                      } else {
                        moveContractBetween(data.id, data.tab, "archive");
                      }
                    } else {
                      moveContractBetween(data.id, data.tab, "active");
                    }
                  }}
                >
                  {data.tab === "archive" ? "Arşivden Çıkart" : "Arşivle"}
                </Dropdown.Item>
                {data.tab !== "archive" && (
                  <Dropdown.Item
                    onClick={() => {
                      if (data.tab !== "archive") {
                        if (
                          data.odemeToplam - data.odemeAlinan > 0 &&
                          data.tab === "active"
                        ) {
                          const confirm = window.confirm(
                            "Kalan ödemesi olan şözleşmeyi iptal etmek istediğinizden emin misiniz?"
                          );
                          if (confirm) {
                            moveContractBetween(data.id, data.tab, "archive");
                          }
                        } else {
                          moveContractBetween(data.id, data.tab, "archive");
                        }
                      } else {
                        moveContractBetween(data.id, data.tab, "active");
                      }
                    }}
                  >
                    Yeni Arşivle
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                  onClick={() => {
                    if (data.tab !== "completed") {
                      if (
                        data.odemeToplam - data.odemeAlinan > 0 &&
                        data.tab === "active"
                      ) {
                        const confirm = window.confirm(
                          "Kalan ödemesi olan sözleşmeyi teslim etmek istediğinizden emin misiniz?"
                        );
                        if (confirm) {
                          moveContractBetween(data.id, data.tab, "completed");
                        }
                      } else {
                        moveContractBetween(data.id, data.tab, "completed");
                      }
                    } else {
                      moveContractBetween(data.id, data.tab, "active");
                    }
                  }}
                >
                  {data.tab === "completed" ? "Aktifleştir" : "Teslim Et"}
                </Dropdown.Item>

                {data.tab !== "completed" && (
                  <Dropdown.Item
                    onClick={() => {
                      if (data.tab !== "completed") {
                        if (
                          data.odemeToplam - data.odemeAlinan > 0 &&
                          data.tab === "active"
                        ) {
                          const confirm = window.confirm(
                            "Kalan ödemesi olan sözleşmeyi teslim etmek istediğinizden emin misiniz?"
                          );
                          if (confirm) {
                            moveContractBetween(data.id, data.tab, "completed");
                          }
                        } else {
                          moveContractBetween(data.id, data.tab, "completed");
                        }
                      } else {
                        moveContractBetween(data.id, data.tab, "active");
                      }
                    }}
                  >
                    Yeni Teslim Et
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                  onClick={() => {
                    if (data.tab !== "cancelled") {
                      const confirm = window.confirm(
                        "Bu sözleşmeyi iptal edilenlere taşımak istediğinize emin misiniz?"
                      );
                      if (confirm) {
                        setActiveDocumentId("");
                        moveContractBetween(data.id, data.tab, "cancelled");
                      }
                    } else {
                      setActiveDocumentId("");
                      moveContractBetween(data.id, data.tab, "active");
                    }
                  }}
                >
                  {data.tab === "cancelled" ? "Geri Al" : "İptal Et"}
                </Dropdown.Item>
                <Dropdown.Item
                  className="text-danger"
                  onClick={() => {
                    const confirm = window.confirm(
                      "Bu şözleşmeyi silmek istediğinize emin misiniz? Bu işlemin geri dönüşü yoktur!"
                    );
                    if (confirm) {
                      deleteContract(data.id);
                    }
                  }}
                >
                  Sil
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </td>
          <th className="">{`100${data.contractID}`}</th>
          <td className="">
            {data.sozlesmeTarihi
              ? moment.utc(data.sozlesmeTarihi).format("DD.MM.YYYY")
              : "Belirtilmedi"}
          </td>
          <td className="">
            {data.damatAdi}
            {<br />}
            {data.gelinAdi}
          </td>
          <td className="">
            {data.damatTelefonu}
            {<br />}
            {data.gelinTelefonu}
          </td>
          <td className="">
            {data.fotoAlbumDisCekimTarihi
              ? moment
                  .utc(data.fotoAlbumDisCekimTarihi)
                  .format("DD.MM.YYYY HH:mm")
              : "Belirtilmedi"}
          </td>
          <td className="fw-bold" style={{ minWidth: "85px" }}>
            {data.odemeToplam} ₺
          </td>
          <td className="fw-bold " style={{ minWidth: "85px" }}>
            {data.odemeAlinan} ₺
          </td>
          <td className="fw-bold " style={{ minWidth: "85px" }}>
            {data.odemeKalan} ₺
          </td>
        </tr>
        <td colSpan={"9"} className="p-0">
          <div
            className={`border rounded-2 ${
              activeDocumentId === data.id ? "my-4 mx-2" : "m-0"
            } ${
              activeDocumentId === data.id ? "p-3" : "p-0"
            } contract-details ${
              activeDocumentId === data.id ? "visible" : ""
            } `}
          >
            <table className="table table-bordered border mb-1">
              <thead className="">
                <tr
                  className="text-uppercase table-light text-center align-middle"
                  style={{ fontSize: ".7em", height: "50px" }}
                >
                  <th
                    className=""
                    style={{ minWidth: "180px", maxWidth: "180px" }}
                  >
                    Adres
                  </th>
                  <th
                    className=""
                    style={{ minWidth: "85px", maxWidth: "95px" }}
                  >
                    Gelinlik Teslimatı
                    <br />
                    Damatlık Teslimatı
                  </th>
                  <th
                    className=""
                    style={{ minWidth: "85px", maxWidth: "95px" }}
                  >
                    Kaftan/Kınalık{<br />} Kira Süresi
                  </th>
                  <th
                    className=""
                    style={{ minWidth: "65px", maxWidth: "75px" }}
                  >
                    Gelinlik Türü
                  </th>
                  <th className="" style={{ maxWidth: "45px" }}>
                    Gelinlik{<br />}Kodu
                  </th>
                  <th className="" style={{ maxWidth: "45px" }}>
                    Kınalık{<br />}Kodu
                  </th>
                  <th className="" style={{ maxWidth: "45px" }}>
                    Kaftan{<br />}Kodu
                  </th>
                </tr>
              </thead>
              <tbody className="">
                <tr
                  className="align-middle text-center"
                  style={{ minHeight: "75px" }}
                >
                  <td
                    className=""
                    style={{ minWidth: "180px", maxWidth: "180px" }}
                  >
                    {data.adres}
                  </td>
                  <td
                    className=""
                    style={{ minWidth: "85px", maxWidth: "95px" }}
                  >
                    {data.gelinlikTeslimati
                      ? moment.utc(data.gelinlikTeslimati).format("DD.MM.YYYY")
                      : "Belirtilmedi"}
                    <br />
                    {data.damatlikTeslimati
                      ? moment.utc(data.damatlikTeslimati).format("DD.MM.YYYY")
                      : "Belirtilmedi"}
                  </td>
                  <td
                    className=""
                    style={{ minWidth: "85px", maxWidth: "95px" }}
                  >
                    <>
                      {data.kinalikKiralikBaslangic &&
                      data.kinalikKiralikBitis ? (
                        <>
                          {moment(data.kinalikKiralikBaslangic).format(
                            "DD.MM.YYYY"
                          )}
                          <br />
                          {moment(data.kinalikKiralikBitis).format(
                            "DD.MM.YYYY"
                          )}
                        </>
                      ) : (
                        "Belirtilmedi"
                      )}
                    </>
                  </td>
                  <td
                    className=""
                    style={{ minWidth: "65px", maxWidth: "75px" }}
                  >
                    {data.gelinlikModeli ? (
                      <>{data.gelinlikModeli || "-"}</>
                    ) : (
                      "Belirtilmedi"
                    )}
                  </td>
                  <td className="" style={{ maxWidth: "45px" }}>
                    {data.gelinlikID ? data.gelinlikID : "-"}
                  </td>
                  <td className="" style={{ maxWidth: "45px" }}>
                    {data.kinalikID ? data.kinalikID : "-"}
                  </td>
                  <td className="" style={{ maxWidth: "45px" }}>
                    {data.kaftanID ? data.kaftanID : "-"}
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="table table-bordered mb-1">
              <thead className="">
                <tr
                  className="text-uppercase table-light text-center align-middle"
                  style={{ fontSize: ".7em", height: "50px" }}
                >
                  <th className="" style={{ maxWidth: "100px" }}>
                    Düğün Tarihi
                  </th>
                  <th
                    className=""
                    style={{ minWidth: "160px", maxWidth: "180px" }}
                  >
                    Gelinlik, Albüm ve Terzilik Detayları
                  </th>
                  <th
                    className=""
                    style={{ minWidth: "90px", maxWidth: "90px" }}
                  >
                    Albüm Ekstraları
                  </th>
                  <th
                    className=""
                    style={{ minWidth: "65px", maxWidth: "65px" }}
                  >
                    Albüm Ekstrası
                  </th>
                  <th className="" style={{ maxWidth: "65px" }}>
                    Albüm Ekstra Ücreti
                  </th>
                  <th
                    className=""
                    style={{ minWidth: "65px", maxWidth: "65px" }}
                  >
                    Gelinlik Ekstrası
                  </th>
                </tr>
              </thead>
              <tbody className="">
                <tr
                  className="align-middle text-center"
                  style={{ minHeight: "75px" }}
                >
                  <td className="text-center" style={{ maxWidth: "100px" }}>
                    {data.dugunTarihi
                      ? moment.utc(data.dugunTarihi).format("DD.MM.YYYY")
                      : "Belirtilmedi"}
                  </td>
                  <td
                    className=""
                    style={{ minWidth: "160px", maxWidth: "180px" }}
                  >
                    {data.gelinlikDetaylari &&
                    data.albumDetaylari &&
                    data.terzilikDetaylari ? (
                      <>
                        {data.gelinlikDetaylari} <hr className="p-0 m-0" />
                        {data.albumDetaylari} <hr className="p-0 m-0" />
                        {data.terzilikDetaylari}
                      </>
                    ) : data.gelinlikDetaylari ? (
                      data.gelinlikDetaylari
                    ) : data.albumDetaylari ? (
                      data.albumDetaylari
                    ) : data.terzilikDetaylari ? (
                      data.terzilikDetaylari
                    ) : (
                      "-"
                    )}
                  </td>
                  <td
                    className=""
                    style={{ minWidth: "90px", maxWidth: "90px" }}
                  >
                    <>
                      {data.fotoAlbumEkstra1 ? (
                        <>
                          {`${data.fotoAlbumEkstra1}`}
                          <br />
                        </>
                      ) : (
                        ""
                      )}

                      {data.fotoAlbumEkstra2 ? (
                        <>
                          {`${data.fotoAlbumEkstra2}`}
                          <br />
                        </>
                      ) : (
                        ""
                      )}

                      {data.fotoAlbumEkstra3 ? (
                        <>
                          {`${data.fotoAlbumEkstra3}`}
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  </td>
                  <td
                    className=""
                    style={{ minWidth: "65px", maxWidth: "65px" }}
                  >
                    {!data.fotoAlbumEkstra1 &&
                    !data.fotoAlbumEkstra2 &&
                    !data.fotoAlbumEkstra3
                      ? ""
                      : null}
                    {data.fotoAlbumEkstra1 ? (
                      <>
                        {` ${data.fotoAlbumEkstra1ucret}₺`}
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    {data.fotoAlbumEkstra2 ? (
                      <>
                        {`${data.fotoAlbumEkstra2ucret}₺`}
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    {data.fotoAlbumEkstra3 ? (
                      <>
                        {`${data.fotoAlbumEkstra3ucret}₺`}
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                  </td>
                  <td className="" style={{ maxWidth: "65px" }}>
                    <>{data.albumUcreti ? `${data.albumUcreti}₺` : "-"}</>
                  </td>
                  <td
                    className=""
                    style={{ minWidth: "65px", maxWidth: "65px" }}
                  >
                    {Number(data.gelinlikEkstrasi) > 0
                      ? `${data.gelinlikEkstrasi}₺`
                      : "-"}
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="table table-bordered">
              <thead className="">
                <tr
                  className="text-uppercase table-light text-center align-middle"
                  style={{ fontSize: ".7em", height: "50px" }}
                >
                  <th className="" style={{ maxWidth: "65px" }}>
                    Duvak Ücreti
                  </th>
                  <th
                    className=""
                    style={{ minWidth: "65px", maxWidth: "65px" }}
                  >
                    Taç Ücreti
                  </th>
                  <th
                    className=""
                    style={{ minWidth: "65px", maxWidth: "65px" }}
                  >
                    Terzilik Ücreti
                  </th>
                  <th
                    className=""
                    style={{ minWidth: "65px", maxWidth: "65px" }}
                  >
                    Kınalık Ücreti
                  </th>
                  <th
                    className=""
                    style={{ minWidth: "65px", maxWidth: "65px" }}
                  >
                    Kuru Tem. Ücreti
                  </th>
                  <th
                    className=""
                    style={{ minWidth: "65px", maxWidth: "65px" }}
                  >
                    Özel Dikim Ücreti
                  </th>
                  <th
                    className=""
                    style={{ minWidth: "160px", maxWidth: "180px" }}
                  >
                    Açıklama
                  </th>
                </tr>
              </thead>
              <tbody className="">
                <tr
                  className="align-middle text-center"
                  style={{ minHeight: "75px" }}
                >
                  <td
                    className=""
                    style={{ minWidth: "65px", maxWidth: "65px" }}
                  >
                    {data.duvakUcreti ? `${data.duvakUcreti}₺` : "-"}
                  </td>
                  <td
                    className=""
                    style={{ minWidth: "65px", maxWidth: "65px" }}
                  >
                    {data.tacUcreti ? `${data.tacUcreti}₺` : "-"}
                  </td>
                  <td
                    className=""
                    style={{ minWidth: "65px", maxWidth: "65px" }}
                  >
                    {data.terzilikUcreti ? `${data.terzilikUcreti}₺` : "-"}
                  </td>
                  <td
                    className=""
                    style={{ minWidth: "65px", maxWidth: "65px" }}
                  >
                    {data.kaftanUcreti ? `${data.kaftanUcreti}₺` : "-"}
                  </td>
                  <td
                    className=""
                    style={{ minWidth: "65px", maxWidth: "65px" }}
                  >
                    {data.kuruTemizlemeUcreti
                      ? `${data.kuruTemizlemeUcreti}₺`
                      : "-"}
                  </td>
                  <td
                    className=""
                    style={{ minWidth: "65px", maxWidth: "65px" }}
                  >
                    {data.gelinlikOzelDikim
                      ? `${data.gelinlikOzelDikim}₺`
                      : "-"}
                  </td>
                  <td
                    className=""
                    style={{ minWidth: "160px", maxWidth: "180px" }}
                  >
                    {data.aciklama ? data.aciklama : "-"}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="row">
              <div className="col-md-auto fw-medium mb-3">
                Alınan Ödeme: {data.odemeAlinan ? data.odemeAlinan + "₺" : "-"}
              </div>
              <div className="col-md-auto fw-medium mb-3">
                İndirim Tutarı:{" "}
                {data.odemeIndirim ? data.odemeIndirim + "₺" : "-"}
              </div>
              <div className="col-md-auto fw-medium mb-3">
                İndirim Çeki Tutarı:{" "}
                {data.indirimCekiTutari ? data.indirimCekiTutari + "₺" : "-"}
              </div>
              <div className="col-md-auto fw-medium mb-3">
                Sözleşme Durumu:{" "}
                {data.tab === "cancelled"
                  ? "İptal Edilmiş"
                  : data.tab === "archive"
                  ? "Arşivlenmiş"
                  : data.tab === "completed"
                  ? "Teslim Edilmiş"
                  : "Aktif"}
              </div>
              <div className="col text-md-end my-auto">
                <button
                  className="btn btn-outline-primary ms-3 ms-md-2 px-3 p-2 fw-bold"
                  type="button"
                  onClick={() => {
                    setActiveDocumentId("");
                  }}
                >
                  <i className="me-1 bi bi-arrow-left-circle-fill" />
                  Gizle
                </button>
              </div>
            </div>
          </div>
        </td>
      </>
    ));
  };

  const renderTotalAmount = () => {
    let totalOdeme = 0;
    let totalAlinan = 0;
    let totalKalan = 0;

    for (const amount in data) {
      if (data[amount].tab === "active") {
        totalAlinan += Number(data[amount].odemeAlinan);
        totalOdeme += Number(data[amount].odemeToplam);
      }
    }
    totalKalan = totalOdeme - totalAlinan;

    return (
      <>
        <div className="shadow-lg col-lg-3 mx-lg-auto my-2 card text-center text-bg-success fw-bold">
          <div className="card-header fs-5 my-1">Toplam Ödeme</div>
          <div className="card-title fs-3 my-lg-4">{totalOdeme} ₺</div>
        </div>
        <div className="shadow-lg col-lg-3 mx-lg-auto my-2 card text-center text-bg-warning fw-bold ">
          <div className="card-header fs-5 my-1">Toplam Alınan Ödeme</div>
          <div className="card-title fs-3 my-lg-4">{totalAlinan} ₺</div>
        </div>
        <div className="shadow-lg col-lg-3 mx-lg-auto my-2 card text-center text-bg-danger fw-bold">
          <div className="card-header fs-5 my-1">Toplam Kalan Ödeme</div>
          <div className="card-title fs-3 my-lg-4">{totalKalan} ₺</div>
        </div>
      </>
    );
  };

  const moveContractBetween = (id, tab, destination) => {
    fetch(process.env.API_URL + "/bridalContracts/move", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
        toWhere: destination,
        tab: tab,
      }),
    })
      .then((response) => {
        if (response.ok) {
          toast.success("Sözleşme başarıyla taşındı!", {
            position: "top-center",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        }
      })
      .catch(() => {
        toast.error("Sözleşme taşınırken bir hata meydana geldi", {
          position: "top-center",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        });
      })
      .finally(() => {
        setTimeout(() => {
          setFetch(true);
        }, 100);
      });
  };

  return (
    <Fragment>
      <div className="container-xl bg-light shadow-sm p-3 border my-3 rounded-2">
        <div
          className="row d-flex align-items-center"
          style={{ minHeight: "60px" }}
        >
          <div className="col">
            <h3 className="fw-bold">Tüm Gelinlik Sözleşmeleri</h3>
          </div>
          <div className="col-md-2">
            <InputMask
              type="text"
              mask="9999999999"
              maskChar=""
              placeholder="Fazla Tutar"
              className="form-control"
              id="fazlaTutar"
            />
          </div>
          <div className="col-md-auto mt-2 mt-md-0">
            <Dropdown as={ButtonGroup}>
              <Button
                className="fw-bold px-3 py-2 focus-ring w-100"
                type="button"
                onClick={() => {
                  handleSecondPrintButtonClick(data, "liste");
                }}
              >
                <i className="me-1 bi bi-printer-fill" />
                Liste Yazdır
              </Button>
              <Dropdown.Toggle
                split
                variant="primary"
                id="dropdown-split-basic"
              />

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => handleSecondPrintButtonClick(data, "gelinlik")}
                >
                  Gelinlik Tipi ({gelinlikModeliFilter})
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleSecondPrintButtonClick(data, "teslimat")}
                >
                  Teslimat Tarihi ({gelinlikTeslimatiFilter})
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleSecondPrintButtonClick(data, "cekim")}
                >
                  Dış Çekim Tarihi ({disCekimFilter})
                </Dropdown.Item>

       

              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-md-auto mt-2 mt-md-0">
            <Button
              className="newContractBtn fw-bold px-3 py-2 focus-ring w-100"
              type="button"
              as={Link}
              to="/bridal-contracts/new"
            >
              <i className="me-1 bi bi-plus-circle-fill" />
              Yeni Sözleşme
            </Button>
          </div>
        </div>
      </div>

      <div className="container-xl bg-light shadow-sm py-4 px-3 my-3 border rounded-2">
        <form id="detailedSearch">
          <div className="row d-flex align-items-center text-uppercase justify-content-center">
            <div className="form-floating col-md-4 mb-3 ">
              <input
                type="text"
                className="form-control"
                id="floatingName"
                placeholder="Müşteri Adı"
              />
              <label
                className="form-label ms-3 fw-bold"
                htmlFor="floatingName"
                style={{ fontSize: ".7em" }}
              >
                müşteri adı
              </label>
            </div>
            <div className="col-md-4 form-floating mb-3 ">
              <InputMask
                type="text"
                mask="0 (999) 999-9999"
                maskChar=""
                placeholder="0 (___) ___-____"
                className="form-control"
                id="floatingPhone"
                ref={phoneInput}
              />
              <label
                className="form-label fw-bold ms-3"
                style={{ fontSize: ".7em" }}
              >
                Telefon No
              </label>
            </div>
            <div className="col-md-4 form-floating mb-3 ">
              <InputMask
                type="text"
                className="form-control"
                id="floatingId"
                mask="100999999999999999"
                maskChar={""}
                placeholder="100_"
                ref={idInput}
              />
              <label
                className="form-label fw-bold ms-3"
                style={{ fontSize: ".7em" }}
              >
                sıra no
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mb-3 mt-auto text-uppercase form-floating">
              <input
                type="date"
                className="form-control"
                id="floatingStartDate"
                placeholder="Başlangıç Tarihi"
              />
              <label
                className="form-label fw-bold ms-2"
                style={{ fontSize: ".7em" }}
              >
                SÖZLEŞME TARİHİ BAŞLANGIÇ
              </label>
            </div>
            <div className="col-md-4 mb-3 mt-auto text-uppercase form-floating">
              <input
                type="date"
                className="form-control"
                id="floatingEndDate"
                placeholder="Bitiş Tarihi"
              />
              <label
                className="form-label fw-bold ms-2"
                style={{ fontSize: ".7em" }}
              >
                SÖZLEŞME TARİHİ BİTİŞ
              </label>
            </div>
            <div className="col-md-4 mt-2 mt-md-3">
              <div className="row my-auto text-center">
                <div className="col ms-0 pe-lg-1">
                  <button
                    className="btn btn-warning fw-bold h-100 w-100 focus-ring focus-ring-warning"
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      setFetch(true);
                    }}
                  >
                    <i className="bi bi-search me-2" />
                    Ara
                  </button>
                </div>
                <div className="col-2 text-end ps-lg-1 ps-md-0 pe-2">
                  <button
                    className="btn btn-primary w-100 focus-ring focus-ring-dark"
                    type="button"
                    onClick={() => {
                      setAdvancedSearch(!advancedSearch);
                      if (advancedSearch) {
                        document.forms[0][8].value = "";
                        document.forms[0][9].value = "";
                        document.forms[0][10].value = "";
                        document.forms[0][11].value = "";
                        document.forms[0][12].checked = false;
                        document.forms[0][13].checked = false;
                        document.forms[0][14].checked = false;
                        document.forms[0][15].checked = false;
                        document.forms[0][16].checked = false;
                      }
                    }}
                  >
                    {!advancedSearch ? (
                      <i className="bi bi-caret-down-fill" />
                    ) : (
                      <i className="bi bi-caret-up-fill" />
                    )}
                  </button>
                </div>
                <div className="col-2 text-end ps-lg-1 ps-md-0 pe-2">
                  <button
                    className="fw-bold btn btn-dark w-100 focus-ring focus-ring-dark"
                    type="button"
                    onClick={() => {
                      document.forms[0].reset();
                      phoneInput.current.setInputValue("");
                      idInput.current.setInputValue("");
                      currentPage.current = 1;
                      setFetch(true);
                    }}
                  >
                    X
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`container-xl ps-0 pe-0 contract-details ${
              advancedSearch ? "visible mt-md-2 mt-3" : ""
            }`}
          >
            <div className="row">
              <div className="col-md-2 form-floating mt-3 mt-md-0">
                <select
                  className="form-select"
                  id="gelinlikFiltresi"
                  name="gelinlikFiltresi"
                  onChange={(e) => {
                    if (
                      e.target[e.target.selectedIndex].innerText !==
                      gelinlikModeliFilter
                    ) {
                      setGelinlikModeliFilter(
                        e.target[e.target.selectedIndex].innerText
                      );
                      setFetch(true);
                    }
                  }}
                  defaultValue=""
                >
                  <option value="">Hepsi</option>
                  <option value="yok">Seçilmedi</option>
                  <option value="helen">Helen</option>
                  <option value="kesim">Ağ Kesim</option>
                  <option value="prenses">Prenses</option>
                  <option value="dikim">Özel Dikim</option>
                </select>
                <label
                  className="form-label ms-2 fw-bold"
                  htmlFor="gelinlikFiltresi"
                  style={{ fontSize: ".95em" }}
                >
                  Gelinlik Tipi
                </label>
              </div>
              <div className="col-md-2 form-floating mt-3 mt-md-0">
                <select
                  className="form-select"
                  id="kinalikFiltresi"
                  name="kinalikFiltresi"
                  defaultValue=""
                >
                  <option value="">Hepsi</option>
                  <option value="kinalik">Kınalık</option>
                  <option value="kaftan">Kaftan</option>
                  <option value="all">Kınalık ve Kaftan</option>
                </select>
                <label
                  className="form-label ms-2 fw-bold"
                  htmlFor="gelinlikFiltresi"
                  style={{ fontSize: ".95em" }}
                >
                  Kınalık & Kaftan
                </label>
              </div>
              <div className="col-md-2 form-floating mt-3 mt-md-0">
                <select
                  className="form-select"
                  id="gelinlikTeslimatFiltresi"
                  name="gelinlikTeslimatFiltresi"
                  onChange={(e) => {
                    if (
                      e.target[e.target.selectedIndex].innerText !==
                      gelinlikTeslimatiFilter
                    ) {
                      setGelinlikTeslimatiFilter(
                        e.target[e.target.selectedIndex].innerText
                      );
                      setFetch(true);
                    }
                  }}
                  defaultValue=""
                >
                  <option value="">Hepsi</option>
                  <option value="belirsiz">Belirsiz</option>
                  <option value="1">Ocak</option>
                  <option value="2">Şubat</option>
                  <option value="3">Mart</option>
                  <option value="4">Nisan</option>
                  <option value="5">Mayıs</option>
                  <option value="6">Haziran</option>
                  <option value="7">Temmuz</option>
                  <option value="8">Ağustos</option>
                  <option value="9">Eylül</option>
                  <option value="10">Ekim</option>
                  <option value="11">Kasım</option>
                  <option value="12">Aralık</option>
                </select>
                <label
                  className="form-label ms-2 fw-bold"
                  htmlFor="gelinlikFiltresi"
                  style={{ fontSize: ".95em" }}
                >
                  Gelinlik Teslimatı
                </label>
              </div>
              <div className="col-md-2 form-floating mt-3 mt-md-0">
                <select
                  className="form-select"
                  id="disCekimFiltresi"
                  name="disCekimFiltresi"
                  onChange={(e) => {
                    if (
                      e.target[e.target.selectedIndex].innerText !==
                      disCekimFilter
                    ) {
                      setDisCekimFilter(
                        e.target[e.target.selectedIndex].innerText
                      );
                      setFetch(true);
                    }
                  }}
                  defaultValue=""
                >
                  <option value="">Hepsi</option>
                  <option value="belirsiz">Belirsiz</option>
                  <option value="1">Ocak</option>
                  <option value="2">Şubat</option>
                  <option value="3">Mart</option>
                  <option value="4">Nisan</option>
                  <option value="5">Mayıs</option>
                  <option value="6">Haziran</option>
                  <option value="7">Temmuz</option>
                  <option value="8">Ağustos</option>
                  <option value="9">Eylül</option>
                  <option value="10">Ekim</option>
                  <option value="11">Kasım</option>
                  <option value="12">Aralık</option>
                </select>
                <label
                  className="form-label ms-2 fw-bold"
                  htmlFor="disCekimFiltresi"
                  style={{ fontSize: ".95em" }}
                >
                  Dış Çekim Tarihi
                </label>
              </div>
              <div className="col-md-auto form-floating my-auto ps-4 mt-3 mt-md-0">
                <div className="row">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      id="duvakFiltresi"
                      name="duvakFiltresi"
                      className="form-check-input"
                    />
                    <label htmlFor="duvakFiltresi" className="form-check-label">
                      Duvak
                    </label>
                  </div>
                </div>
                <div className="row mt-2 mt-md-0">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      id="tacFiltresi"
                      name="tacFiltresi"
                      className="form-check-input"
                    />
                    <label htmlFor="tacFiltresi" className="form-check-label">
                      Taç
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-auto form-floating my-auto ps-4 mt-2 mt-md-0">
                <div className="row">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      id="terzilikFiltresi"
                      name="terzilikFiltresi"
                      className="form-check-input"
                    />
                    <label
                      htmlFor="terzilikFiltresi"
                      className="form-check-label"
                    >
                      Terzilik
                    </label>
                  </div>
                </div>
                <div className="row mt-2 mt-md-0">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      id="albumFiltresi"
                      name="albumFiltresi"
                      className="form-check-input"
                    />
                    <label
                      htmlFor="kaftanFiltresi"
                      className="form-check-label"
                    >
                      Fotoğraf
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md form-floating my-auto ps-4 mt-2 mt-md-0">
                <div className="row justify-content-center">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      id="onBinFiltre"
                      name="onBinFiltre"
                      className="form-check-input"
                    />
                    <label htmlFor="onBinFiltre" className="form-check-label">
                      10.000TL'den fazla borcu olanlar
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div
        className="listContainer container-xl bg-light shadow pt-4 pb-5 px-3 my-3 border  rounded-2"
        style={{ minHeight: "100px" }}
      >
        <div className="row pb-3">
          <div
            className={`${
              currentTab.current !== "archive" &&
              currentTab.current !== "cancelled"
                ? "col-auto"
                : "col-lg"
            } text-center text-lg-start`}
          >
            <div className="row fw-bold fs-5">
              <h3 className="fw-bold ms-1 ps-2">
                {currentTab.current === "archive"
                  ? "Arşivlenenler"
                  : currentTab.current === "cancelled"
                  ? "İptal Edilenler"
                  : currentTab.current === "active"
                  ? "Aktif Sözleşmeler"
                  : "Bütün Sözleşmeler"}{" "}
                Listesi
              </h3>
            </div>
            <div className="text-muted">
              Veritabanında toplam {totalDocumentCount.current} sözleşme var
            </div>
          </div>
          {currentTab.current !== "archive" &&
            currentTab.current !== "cancelled" && (
              <div className="col-lg pt-2 ps-4 ps-lg-0">
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="sadeceKalanlar"
                    className="form-check-input"
                    onChange={() => {
                      setActiveDocumentId("");
                      setFetch(true);
                      setOnlyWithPaymentsLeft((prev) => !prev);
                    }}
                  />
                  <label htmlFor="sadeceKalanlar" className="form-check-label">
                    Sadece Ödemesi Kalanları Göster
                  </label>
                </div>
              </div>
            )}

          {currentTab.current !== "active" && (
            <div className="col-lg-auto mt-3 mt-lg-0 px-4 px-lg-2 text-end">
              <select
                className="form-select"
                onChange={(e) => {
                  if (orderBy.current !== e.target.value) {
                    orderBy.current = e.target.value;
                    setFetch(true);
                  }
                }}
                defaultValue={orderBy.current}
              >
                <option value="">Varsayılan Sıralama</option>
                <option value="movedAt.asc">Taşınma Tarihi: Eski</option>
                <option value="movedAt.desc">Taşınma Tarihi: Yeni</option>
              </select>
            </div>
          )}
          <div className="col-lg-auto mt-3 mt-lg-0 px-4 px-lg-2 text-end">
            <select
              className="form-select"
              onChange={(e) => {
                if (currentTab.current !== e.target.value) {
                  currentTab.current = e.target.value;
                  setFetch(true);
                }
              }}
              defaultValue={currentTab.current}
            >
              <option value="all">Tüm Sözleşmeler</option>
              <option value="active">Aktif Olanlar</option>
              <option value="archive">Arşivlenenler</option>
              <option value="completed">Teslim Edilenler</option>
              <option value="cancelled">İptal Edilenler</option>
            </select>
          </div>
          <div className="col-lg-2 mt-3 mt-lg-0 px-4 px-lg-2 text-end">
            <select
              className="form-select"
              defaultValue={filterOption}
              onChange={(e) => {
                setFilterOption(e.target.value);
                setActiveDocumentId("");
                setFetch(true);
              }}
            >
              <option value={"all"}>Tüm zamanlar</option>
              <option value={"today"}>Son 24 saat</option>
              <option value={"week"}>Son hafta</option>
              <option value={"month"}>Son 30 gün</option>
            </select>
          </div>
        </div>
        {loading ? (
          <div className="d-flex justify-content-center align-middle">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Yükleniyor...</span>
            </div>
          </div>
        ) : error ? (
          <div className="row ">
            <div className="col-md-4 mx-auto text-center">
              <div className="alert alert-danger mx-0">
                <i className="bi bi-exclamation-circle-fill" /> {error}
              </div>
              <button
                className="btn btn-primary fw-bold focus-ring"
                onClick={() => {
                  setFetch(true);
                }}
              >
                Tekrar dene
              </button>
            </div>
          </div>
        ) : (
          <Fragment>
            <div
              className="contractsTable container-fluid border overflow-x-auto border-lg shadow-sm rounded-3 bg-white p-0 px-lg-3"
              style={{ minHeight: "525px" }}
            >
              <table className="table table-bordered mt-lg-3  overflow-x-auto">
                <thead className="">
                  <tr
                    className="text-uppercase text-center table-light border"
                    style={{ fontSize: ".8em", height: "60px" }}
                  >
                    <th className="text-center" style={{ minWidth: "55px" }}>
                      Bilgi
                    </th>
                    <th className="text-center" style={{ minWidth: "65px" }}>
                      Sıra No
                    </th>
                    <th
                      className=""
                      style={{ minWidth: "100px", maxWidth: "100px" }}
                    >
                      Sözleşme Tarihi
                    </th>
                    <th
                      className=""
                      style={{ minWidth: "150px", maxWidth: "150px" }}
                    >
                      Müşteri Adları
                    </th>
                    <th className="" style={{ maxWidth: "120px" }}>
                      Telefon No
                    </th>
                    <th
                      className=""
                      style={
                        activeDocumentId === data.id
                          ? { width: "250px" }
                          : { width: "160px" }
                      }
                    >
                      Dış Çekim Tarihi
                    </th>
                    <th
                      className=""
                      style={{ minWidth: "75px", maxWidth: "85px" }}
                    >
                      Toplam
                    </th>
                    <th
                      className=""
                      style={{ minWidth: "75px", maxWidth: "85px" }}
                    >
                      Alınan
                    </th>
                    <th
                      className=""
                      style={{ minWidth: "75px", maxWidth: "85px" }}
                    >
                      Kalan
                    </th>
                  </tr>
                </thead>

                <tbody className="">{renderContracts()}</tbody>
              </table>
            </div>
            <div className="row mt-5">
              <div className="col-md text-center align-middle text-md-end align-middle mx-auto">
                <ul className="pagination justify-content-center">
                  <li className="page-item">
                    <a
                      className="page-link"
                      href="#"
                      onClick={() => {
                        if (currentPage.current > 1) {
                          currentPage.current -= 1;
                          setFetch(true);
                        }
                      }}
                    >
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  {
                    <Pagination
                      totalPages={totalPages}
                      currentPage={currentPage}
                      setFetch={setFetch}
                    />
                  }
                  <li className="page-item">
                    <a
                      className="page-link"
                      href="#"
                      onClick={() => {
                        if (currentPage.current < totalPages.current) {
                          currentPage.current += 1;
                          setFetch(true);
                        }
                      }}
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {currentTab.current === "active" || currentTab.current === "all" ? (
              <div className="row mx-5 mt-4 mt-lg-3">{renderTotalAmount()}</div>
            ) : null}
            {updateModal && (
              <UpdateBridalContractModal
                toggleModal={toggleUpdateModal}
                closeModal={closeModal}
                data={activeDocument.current}
              />
            )}
            {historyModal && (
              <ContractHistoryModal
                toggleModal={toggleHistoryModal}
                type="bridal"
                data={activeDocumentHistory.current}
              />
            )}

        
          </Fragment>
        )}
      </div>
      <ToastContainer
        position="top-center"
        autoClose={2500}
        limit={3}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition:Flip
      />
    </Fragment>
  );
}

export default BridalContracts;
